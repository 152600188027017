import React from 'react'
import logo from '../img/logo.png'
import {ReactSearchAutocomplete} from 'react-search-autocomplete'
import {withPrefix} from 'gatsby'
import search from '../img/search.svg'

const handleOnSearch = (string, results) => {
    // console.log(string, results)
}

const handleOnHover = (result) => {
    // console.log(result)
}
const isBrowser = typeof window !== "undefined"

const handleOnSelect = (item) => {
    window.location = withPrefix(item.name)
}

const handleOnFocus = () => {
    // console.log('Focused')
}

const Navbar = class extends React.Component {
    state = {
        bilgilendirme: false,
        search: 0,
    }

    render() {
        return (
            <nav
                className="navbar has-background-white"
                role="navigation"
                aria-label="main-navigation"
            >
                {this.state.bilgilendirme ? (
                    <div className="box open-sans-regular">
                        <div className="bilgilendirmeSeyi"></div>
                        {window.location.href.includes("/en/") ?
                            (<p> DIGITAL BUSINESS CARD FOR ARTISTS </p>) :
                            (<p> SANATÇILAR İÇİN DİJİTAL KARTVİZİT </p>)}
                        <p className="bilgilendirmeYazi">
                            <a
                                title="email"
                                target="_blank"
                                rel="noopener noreferrer"
                                href="mailto:info@artvizit.co"
                            >
                                {' '}
                                info@artvizit.co
                            </a>
                        </p>
                        {window.location.href.includes("/en/") ? (
                            <p className="bilgilendirmeYazi">
                                {' '}
                                Artvizit.co is a non-profit digital portfolio website created for the contemporary
                                artists
                                of Turkey. Artists may be contacted via the links provided on their Artvizit profile.
                            </p>) : (
                            <p className="bilgilendirmeYazi">
                                {' '}
                                Artvizit.co, yayımlanan bilgileri sanatçının kendisinden alır. Kâr
                                amacı gütmez ve sanat eseri satış platformu değildir. Sanatçıya
                                ulaşmak için, isminin altında yer alan iletişim kanallarını
                                kullanabilirsiniz.
                            </p>
                        )
                        }
                    </div>
                ) : (
                    <div></div>
                )}
                <div className="container">
                    <div className="navbar-brand">
                        {/* <a title="email" target="_blank" rel="noopener noreferrer" href="mailto:info@artvizit.co" className="navbar-item" title="Email Gönder"> */}
                        <div className="navbar-item">
                            <img
                                src={logo}
                                alt="Artvizit"
                                onClick={() => {
                                    if (
                                        isBrowser &&
                                        window.location.pathname.includes('etiket')
                                    ) {
                                        window.history.back()
                                    } else {
                                        this.setState({bilgilendirme: !this.state.bilgilendirme})
                                    }
                                }}
                            />
                        </div>

                        <div className="navbar-item">
                            {this.state.search === 0 && isBrowser ? (
                                <>
                                    {window.location.href.includes("/en/") ? (
                                        <div onClick={() => {
                                            this.setState({lang: 0})
                                            if (window !== undefined) {
                                                window.location.href = "/" + window.location.pathname.slice(4)
                                            }
                                        }}>
                                            <div className="searchImg">Türkçe</div>
                                        </div>
                                    ) : (
                                        <div onClick={() => {
                                            this.setState({lang: 1})
                                            if (isBrowser) {
                                                window.location.href = "/en" + window.location.pathname
                                            }
                                        }}>
                                            <div className="searchImg">English</div>

                                        </div>
                                    )}
                                    <img
                                        className="searchIcon"
                                        src={search}
                                        alt="search"
                                        style={{width: '1.1em', height: '1.1em'}}
                                        onClick={() => {
                                            this.setState({search: 1})
                                        }}
                                    />
                                </>
                            ) : (
                                <div></div>
                            )}

                            {this.state.search === 1 && isBrowser ? (
                                <>
                                    {window.location.href.includes("/en/") ? (
                                        <div onClick={() => {
                                            this.setState({lang: 0})
                                            if (window !== undefined) {
                                                window.location.href = "/" + window.location.pathname.slice(4)
                                            }
                                        }}>
                                            <div className="searchImg">Türkçe</div>
                                        </div>
                                    ) : (
                                        <div onClick={() => {
                                            this.setState({lang: 1})
                                            if (window !== undefined) {
                                                window.location.href = "/en" + window.location.pathname
                                            }
                                        }}>
                                            <div className="searchImg">English</div>

                                        </div>
                                    )}
                                    <img
                                        className="searchIcon"
                                        src={search}
                                        alt="search"
                                        style={{width: '1.1em', height: '1.1em'}}
                                        onClick={() => {
                                            this.setState({search: 2})
                                        }}
                                    />
                                </>

                            ) : (
                                <div></div>
                            )}

                            {this.state.search === 2 && isBrowser ? (
                                <>
                                    {window.location.href.includes("/en/") ? (
                                        <div onClick={() => {
                                            this.setState({lang: 0})
                                            if (window !== undefined) {
                                                window.location.href = "/" + window.location.pathname.slice(4)
                                            }
                                        }}>
                                            <div className="searchImg">Türkçe</div>
                                        </div>
                                    ) : (
                                        <div onClick={() => {
                                            this.setState({lang: 1})
                                            if (window !== undefined) {
                                                window.location.href = "/en" + window.location.pathname
                                            }
                                        }}>
                                            <div className="searchImg">English</div>

                                        </div>
                                    )}
                                    <img
                                        className="searchIcon"
                                        src={search}
                                        alt="search"
                                        style={{width: '1.1em', height: '1.1em'}}
                                        onClick={() => {
                                            this.setState({search: 3})
                                        }}
                                    />
                                </>
                            ) : (
                                <div></div>
                            )}

                            {this.state.search === 3 && isBrowser  ? (
                                <>
                                    {window.location.href.includes("/en/") ? (
                                        <div onClick={() => {
                                            this.setState({lang: 0})
                                            if (window !== undefined) {
                                                window.location.href = "/" + window.location.pathname.slice(4)
                                            }
                                        }}>
                                            <div className="searchImg2">Türkçe</div>
                                        </div>
                                    ) : (
                                        <div onClick={() => {
                                            this.setState({lang: 1})
                                            if (window !== undefined) {
                                                window.location.href = "/en" + window.location.pathname
                                            }
                                        }}>
                                            <div className="searchImg2">English</div>
                                        </div>
                                    )}
                                    <img
                                        className="searchIcon"
                                        src={search}
                                        alt="search"
                                        style={{width: '1.1em', height: '1.1em'}}
                                        onClick={() => {
                                            this.setState({search: 0})
                                        }}
                                    />
                                </>
                            ) : (
                                <div></div>
                            )}

                            {this.state.search == 1 || this.state.search == 3 ? (
                                <div className="searchArtvizit">
                                    <ReactSearchAutocomplete
                                        items={this.props.items}
                                        onSearch={handleOnSearch}
                                        onHover={handleOnHover}
                                        onSelect={handleOnSelect}
                                        onFocus={handleOnFocus}
                                        showIcon={false}
                                        placeholder={`Sanatçı ara...`}
                                        maxResults={1}
                                        styling={{height: '36px'}}
                                    />
                                </div>
                            ) : (
                                <div></div>
                            )}
                        </div>
                    </div>
                </div>
            </nav>
        )
    }
}

export default Navbar
